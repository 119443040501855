:root {
  --PrimaryColor: hsl(199, 100%, 33%);
  --SecondaryColor: hsl(187, 85%, 43%);
  --ThirdColor: hsl(147, 92%, 43%);
  --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDream: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 240, 246);
  --cardBG: rgb(255, 255, 255);
}

.info .secContainer .mainContent {
  padding: 2rem 0;
  gap: 1rem;
}

.info .secContainer .titlesDiv {
  text-align: center;
  margin-bottom: 3rem;
}

.info .secContainer .titlesDiv h2 {
  padding: 1rem 0;
  font-size: 25px;
  color: var(--blackColor);
}

.info .secContainer .titlesDiv p {
  text-align: justify;
  font-size: 15px;
  color: var(--textColor);
  line-height: 22px;
}

.info .secContainer .mainContent .filInfo {
  overflow: hidden;
  border-radius: 1rem;
  -webkit-box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.334);
          box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.334);
}

.info .secContainer .mainContent .filInfo .infoImage {
  height: 250px;
  width: 101%;
  position: relative;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.info .secContainer .mainContent .filInfo .infoImage .media {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
    object-fit: cover;
}

.info .secContainer .mainContent .filInfo .infoImage .overlayInfo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.846)), to(rgba(0, 0, 0, 0.759)));
  background: linear-gradient(rgba(0, 0, 0, 0.846), rgba(0, 0, 0, 0.759));
  bottom: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
}

.info .secContainer .mainContent .filInfo .infoImage .overlayInfo h2 {
  color: var(--whiteColor);
  font-weight: 600;
}

.info .secContainer .mainContent .filInfo .infoImage .overlayInfo p {
  color: var(--whiteColor);
  opacity: .7;
  text-align: justify;
  font-size: 15px;
}

.info .secContainer .mainContent .filInfo .infoImage .overlayInfo .icon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: .5rem;
  background: var(--Madeko);
  font-size: 2.5rem;
  border-radius: 50%;
  border: 1px solid transparent;
  color: var(--whiteColor);
  -webkit-transition: .3s ease;
  transition: .3s ease;
}

.info .secContainer .mainContent .filInfo .infoImage .overlayInfo .icon:hover {
  background: var(--ThirdColor);
  border: 1px solid transparent;
}

.info .secContainer .mainContent .filInfo .infoFooter {
  padding: 1rem;
}

.info .secContainer .mainContent .filInfo .infoFooter .number {
  font-size: 2rem;
  font-weight: 700;
  color: var(--blackColor);
  position: relative;
}

.info .secContainer .mainContent .filInfo .infoFooter .number span {
  font-size: 26px;
}

.info .secContainer .mainContent .filInfo .infoFooter .infoText {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.twoLineText {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: justify;
  text-overflow: ellipsis;
}

.info .secContainer .mainContent .filInfo .infoFooter .infoText p {
  color: var(--blackColor);
  font-size: 15px;
}

.info .secContainer .mainContent .filInfo:hover .overlayInfo {
  overflow: visible;
  padding: 1rem 1.5rem;
  height: 100%;
}

@media screen and (min-width: 480px) {
  .info {
    padding-top: 5rem;
  }
}

.newspaper-article {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.newspaper-content h1 {
  margin: 0;
  font-size: 2.5rem;
  color: var(--blackColor);
}

.closeButton {
  position: absolute;
  top: 25px;
  right: 25px;
  font-size: 35px;
  color: var(--whiteColor);
  cursor: pointer;
  z-index: 10;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
}

.closeButton:hover {
  color: orangered;
  background: rgba(0, 0, 0, 0.8);
  transform: scale(1.1);
}

.newspaper-content {
  background-color: #fff;
  padding: 2rem;
  position: relative;
  width: 80%;
  border-radius: 10px;
  max-width: 1000px;
  overflow-y: auto;
  max-height: 100%;
}

@media (max-width: 750px) {
  .newspaper-content {
    width: 90%;
    max-height: 80%;
  }
}

.newspaper-content img,
.newspaper-content video {
  max-width: 100%;
  height: 400px;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}

.newspaper-content p {
  color: var(--textColor);
  line-height: 1.5;
  white-space: pre-line;
}

.intro {
  color: black;
  text-align: justify;
  margin-top: 1rem;
  line-height: 1.5;
  white-space: pre-line;
}
