:root{
    --PrimaryColor: hsl(199, 100%, 33%);
    --SecondaryColor: hsl(187, 85%, 43%);
    --ThirdColor: hsl(147, 92%, 43%);
    --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(201, 33%, 16%);
    --textColor: hsl(240, 4%, 36%);
    --whiteColorDream: hsl(0, 0%, 93%);
    --greyText: rgb(190, 190, 190);
    --inputColor: rgb(239, 239, 239);
    --bodyColor: rgb(240, 240, 246);
    --cardBG: rgb(255, 255, 255);
}

.p{
    font-size: 15px;
    color: var(--textColor);
    line-height: 22px;
}

.h4{
    padding: 1rem 0;
    font-weight: 600;
    color: var(--blackColor);
}

.h2{
    padding: 1rem 0;
    font-size: 25px;
    color: var(--blackColor);
}

.support{
    .sectionContainer{
        .titlesDiv{
            text-align: center;

            small{
                letter-spacing: .5rem;
                text-transform: uppercase;
                font-size: 13px;
                color: var(--blackColor);
                line-height: 1.5rem;
            }

            h2{
                padding: 1rem 0;
                font-size: 25px;
                color: var(--blackColor);
            }

            p{
                text-align: left;
                font-size: 15px;
                color: var(--textColor);
                line-height: 22px;
            }     
        }

        .infoDiv{
            margin-top: 2rem;
            margin-bottom: -5rem;
            gap: 1.5rem;

            .textDiv{
                gap: 1.5rem;

                .singleInfo{
                    .number{
                        padding: 5px 12px;
                        width: 40px;
                        border-radius: 3rem;
                        text-align: center;
                        font-size: 15px;
                        font-weight: 600;
                        color: var(--whiteColor);
                    }

                    .colorOne{
                        background: var(--PrimaryColor);
                    }

                    .colorTwo{
                        background: var(--SecondaryColor);                       
                    }

                    .colorThree{
                        background: var(--ThirdColor);                       
                    }

                    h4{
                        padding: 1rem 0;
                        font-weight: 600;
                        color: var(--blackColor);
                    }

                    p{
                        font-size: 15px;
                        color: var(--textColor);
                        line-height: 22px;
                    }   
                }
            }

            .imgDiv{
                margin: auto;

                img{
                    width: 100%;
                    max-width: 480px;
                }
            }
        }
    }
}

// Media Queries ===============================================>
@media screen and (min-width: 570px) {
    .support{
        .sectionContainer{
            .titlesDiv{
                p{
                    font-size: 15px;
                }
            }

            .infoDiv{
                .textDiv{
                    margin-top: 2rem;
                    grid-template-columns: repeat(2,1fr);
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .support{
        .sectionContainer{
            .titlesDiv{
                p{
                    text-align: center;
                    font-size: 15px;
                }
            }

            .infoDiv{
                grid-template-columns: repeat(2,1fr);
                gap: 2rem;
                margin-top: 5rem;

                .textDiv{
                    margin-top: -1.5rem;
                    grid-template-columns: repeat(1,1fr);
                }

                .imgDiv{
                    margin: auto;
    
                    img{
                        width: 480px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1332px) {
    .support{
        .sectionContainer{
            .titlesDiv{
                p{
                    text-align: center;
                    font-size: 15px;
                }
            }
            .infoDiv{
                gap: 1rem;

                .textDiv{
                    .singleInfo{
                        p{
                            max-width: 500px;
                        }
                    }
                }

                .imgDiv{
                    margin: auto;
    
                    img{
                        width: 620px;
                    }
                }
            }
        }
    }
}

