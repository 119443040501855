:root {
  --PrimaryColor: hsl(199, 100%, 33%);
  --SecondaryColor: hsl(187, 85%, 43%);
  --ThirdColor: hsl(147, 92%, 43%);
  --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDream: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 240, 246);
  --cardBG: rgb(255, 255, 255);
}

.materials {
  margin-top: -5rem !important;
}

.p {
  font-size: 15px;
  color: var(--textColor);
  line-height: 22px;
}

.h4 {
  padding: 1rem 0;
  font-weight: 600;
  color: var(--blackColor);
}

.h2 {
  padding: 1rem 0;
  font-size: 25px;
  color: var(--blackColor);
}

.materials .sectionContainer {
  text-align: center;
}

.materials .sectionContainer .materialsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  gap: 1.5rem;
}

.materials .sectionContainer .materialsContainer .singleMaterial {
  position: relative;
  height: 400px;
  width: 100%;
  max-width: 300px;
  overflow: hidden;
  border-radius: 15px;
  gap: .5rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  margin: auto;
}

.materials .sectionContainer .materialsContainer .singleMaterial .materialImage, .materials .sectionContainer .materialsContainer .singleMaterial .ownerImage, .materials .sectionContainer .materialsContainer .singleMaterial .ownerName {
  position: absolute;
}

.materials .sectionContainer .materialsContainer .singleMaterial .materialImage {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -o-object-fit: cover;
     object-fit: cover;
}

.materials .sectionContainer .materialsContainer .singleMaterial .ownerImage {
  height: 50px;
  width: 50px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  bottom: 90px;
  left: 40%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border: 5ps solid rgba(225, 225, 225, 0.637);
  -webkit-transition: .4s ease-in-out;
  transition: .4s ease-in-out;
  -webkit-animation: animated 2.3s infinite;
          animation: animated 2.3s infinite;
}

.materials .seeAll {
    margin-top: 2rem;
    cursor: pointer;
    text-align: right;
    font-weight: 600;
    color: var(--textColor);
    line-height: 22px;
}

.materials .seeAll:hover {
  font-weight: 600;
  color: var(--Madeko);
}

@-webkit-keyframes animated {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes animated {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

.materials .sectionContainer .materialsContainer .singleMaterial .ownerName {
  bottom: 0;
  width: 100%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  background: var(--whiteColor);
  padding: 1.5rem 1rem;
  -webkit-transition: .4s ease-in-out;
  transition: .4s ease-in-out;
  -webkit-animation: animatedBorders 2.3s infinite;
          animation: animatedBorders 2.3s infinite;
}

.materials .sectionContainer .materialsContainer .singleMaterial .ownerName span {
  display: block;
  color: var(--Madeko);
  font-weight: 700;
}


.eyeIcon {
  position: absolute;
  bottom: 90px;
  left: 43%;
  transform: translateX(-50%);
  color: var(--whiteColor);
  font-size: 45px;
  cursor: pointer;
  transition: 0.4s ease-in-out;
  animation: animated 2.3s infinite;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 50%;
}

.eyeIcon:hover {
  color: var(--Madeko);
  background: var(--whiteColor);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  position: relative;
  max-width: 90%;
  max-height: 90vh;
  background: var(--whiteColor);
  padding: 20px;
  border-radius: 8px;
}

.modalImage {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
}

.closeButton {
  position: absolute;
  top: 25px;
  right: 25px;
  font-size: 35px;
  color: var(--whiteColor);
  cursor: pointer;
  z-index: 10;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
}

.closeButton:hover {
  color: orangered;
  background: rgba(0, 0, 0, 0.8);
  transform: scale(1.1);
}

.showAllModal {
  width: 90%;
  max-width: 1200px;
  background: var(--whiteColor);
  border-radius: 8px;
  position: relative;
  padding: 20px;
}

.mainImageContainer {
  margin-top: .5rem;
  margin-bottom: 1rem;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainImage {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.thumbnailContainer {
  overflow-x: auto;
  padding-top: 10px;
}

.thumbnailStrip {
  display: flex;
  gap: 1rem;
}

.thumbnailItem {
  flex-shrink: 0;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 2px solid transparent;
}

.thumbnailItem:hover {
  transform: translateY(-5px);
}

.thumbnailImage {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
}

.activeThumbnail {
  border: 2px solid transparent;
}

@media screen and (max-width: 768px) {
  .mainImageContainer {
    height: 350px;
  }

  .materialsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .thumbnailImage {
    width: 80px;
    height: 80px;
  }
}

@media screen and (max-width: 480px) {
  .materialsContainer {
    grid-template-columns: 1fr;
  }
}

@-webkit-keyframes animatedBorders {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes animatedBorders {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@media screen and (min-width: 551px) {
  .materials .materialsContainer {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    margin-top: 5rem;
  }
}

.iconSeeAll {
  padding: .2rem;
  background: var(--Madeko);
  font-size: 1.5rem;
  margin-left: 10px;
  border-radius: 50%;
  border: 1px solid transparent;
  color: var(--whiteColor);
  -webkit-transition: .3s ease;
  transition: .3s ease;
}
