:root {
  --PrimaryColor: hsl(199, 100%, 33%);
  --SecondaryColor: hsl(187, 85%, 43%);
  --ThirdColor: hsl(147, 92%, 43%);
  --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDream: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 240, 246);
  --cardBG: rgb(255, 255, 255);
}

.p {
  font-size: 15px;
  color: var(--textColor);
  line-height: 22px;
}

.h4 {
  padding: 1rem 0;
  font-weight: 600;
  color: var(--blackColor);
}

.h2 {
  padding: 1rem 0;
  font-size: 25px;
  color: var(--blackColor);
}

.support .sectionContainer .titlesDiv {
  text-align: center;
}

.support .sectionContainer .titlesDiv small {
  letter-spacing: .5rem;
  text-transform: uppercase;
  font-size: 13px;
  color: var(--blackColor);
  line-height: 1.5rem;
}

.support .sectionContainer .titlesDiv h2 {
  padding-bottom: 1rem;
  font-size: 25px;
  color: var(--blackColor);
}

.support .sectionContainer .titlesDiv p {
  text-align: justify !important;
  font-size: 15px;
  color: var(--textColor);
  line-height: 22px;
}

.support .sectionContainer .infoDiv {
  padding-top: 2rem;
  margin-bottom: -5rem;
  gap: 1.5rem;
  overflow-x: hidden;
}

.support .sectionContainer .infoDiv .textDiv {
  padding: 0.5rem;
  gap: 1.5rem;
}

.support .sectionContainer .infoDiv .textDiv .singleInfo .number {
  padding: 5px 12px;
  width: 40px;
  border-radius: 3rem;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  color: var(--whiteColor);
}

.support .sectionContainer .infoDiv .textDiv .singleInfo .colorOne {
  background: var(--PrimaryColor);
}

.support .sectionContainer .infoDiv .textDiv .singleInfo .colorTwo {
  background: var(--SecondaryColor);
}

.support .sectionContainer .infoDiv .textDiv .singleInfo .colorThree {
  background: var(--ThirdColor);
}

.support .sectionContainer .infoDiv .textDiv .singleInfo h4 {
  padding: 1rem 0;
  font-weight: 600;
  color: var(--blackColor);
}

.support .sectionContainer .infoDiv .textDiv .singleInfo p {
  font-size: 15px;
  color: var(--textColor);
  line-height: 22px;
}

.support .sectionContainer .infoDiv .imgDiv {
  margin: auto;
}

.support .sectionContainer .infoDiv .imgDiv img {
  width: 100%;
  max-width: 900px;
}

@media screen and (min-width: 570px) {
  .support .sectionContainer .titlesDiv p {
    font-size: 15px;
  }

  .support .sectionContainer .infoDiv .textDiv {
    margin-top: 2rem;
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 768px) {
  .support .sectionContainer .titlesDiv p {
    text-align: center;
    font-size: 15px;
  }

  .support .sectionContainer .infoDiv {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    margin-top: 5rem;
  }

  .support .sectionContainer .infoDiv .textDiv {
    margin-top: -1.5rem;
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
  }

  .support .sectionContainer .infoDiv .imgDiv {
    margin: auto;
  }

  .support .sectionContainer .infoDiv .imgDiv img {
    width: 500px;
  }
}

@media screen and (min-width: 1332px) {
  .support .sectionContainer .titlesDiv p {
    text-align: center;
    font-size: 15px;
  }

  .support .sectionContainer .infoDiv {
    gap: 1rem;
  }

  .support .sectionContainer .infoDiv .textDiv .singleInfo p {
    max-width: 500px;
    text-align: justify;
  }

  .support .sectionContainer .infoDiv .imgDiv {
    margin: auto;
  }

  .support .sectionContainer .infoDiv .imgDiv img {
    width: 620px;
  }
}
