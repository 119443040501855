:root {
  --PrimaryColor: hsl(199, 100%, 33%);
  --SecondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDream: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 240, 246);
  --cardBG: rgb(255, 255, 255);
}

.header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  width: 100%;
  padding: 1rem;
  z-index: 1000;
}

.header.scrolled {
  background: var(--whiteColor);
  box-shadow: 0 2px 4px rgba(2, 15, 29, 0.242);
}

.header .logo {
  color: var(--whiteColor);
  font-weight: 600;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  object-fit: contain;
}

.header.scrolled .logo {
  color: var(--blackColor);
}

.header .logo img {
  width: 75px;
  height: 65px;
  margin-right: .5rem;
}

@media screen and (max-width: 768px) {
  .header .navBar {
    position: absolute;
    background: var(--whiteColorDream);
    height: max-content;
    width: 80%;
    border-radius: 1rem;
    top: -500px;
    left: 50%;
    transform: translate(-50%, 0);
    opacity: 0;
    visibility: hidden;
    z-index: 10;
    box-shadow: 0 2px 4px rgba(2, 15, 29, 0.242);
    transition: all 0.8s ease-in-out;
    padding: 1rem;
  }

  .header .activeNavbar {
    top: 7rem;
    opacity: 1;
    visibility: visible;
  }

  .header .navBar .navLists {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: auto;
    padding: 0.5rem 0;
  }

  .header .navBar .navLists .navItem {
    padding: 0.5rem 0;
    transform: translateY(25px);
    opacity: 0;
  }

  .header .activeNavbar .navLists .navItem {
    animation: slideIn 0.6s ease forwards;
  }

  @keyframes slideIn {
    0% {
      transform: translateY(25px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .header .activeNavbar .navLists .navItem:nth-child(1) { animation-delay: 0.2s; }
  .header .activeNavbar .navLists .navItem:nth-child(2) { animation-delay: 0.3s; }
  .header .activeNavbar .navLists .navItem:nth-child(3) { animation-delay: 0.4s; }
  .header .activeNavbar .navLists .navItem:nth-child(4) { animation-delay: 0.5s; }
  .header .activeNavbar .navLists .navItem:nth-child(5) { animation-delay: 0.6s; }
  .header .activeNavbar .navLists .btn { animation-delay: 0.7s; }

  .header .navBar .navLists .navItem .navLink {
    color: var(--whiteColor);
    font-weight: 600;
    transition: color 0.3s ease;
  }

  .header.scrolled .navBar .navLists .navItem .navLink {
    color: var(--textColor);
  }

  .header .navBar .navLists .navItem .navLink:hover {
    color: var(--Madeko);
  }

  .header .navBar .navLists .btn {
    margin-top: 1rem;
    transform: translateY(25px);
    opacity: 0;
  }

  .header .activeNavbar .navLists .btn {
    animation: slideIn 0.6s ease forwards;
  }

  .header .navBar .navLists .btn {
    font-weight: 600;
    font-size: 15px !important;
    color: var(--whiteColor);
  }

  .header .navBar .closeNavbar {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    color: var(--Madeko);
    transition: color 0.3s ease, transform 0.3s ease;
  }

  .header .navBar .closeNavbar:hover {
    color: var(--ThirdColor);
    transform: rotate(90deg);
  }
}

.header .toggleNavbar .icon {
  font-size: 25px;
  color: var(--Madeko);
  transition: color 0.3s ease, transform 0.3s ease;
}

.header .toggleNavbar:hover .icon {
  color: var(--ThirdColor);
  transform: rotate(180deg);
}

@media screen and (min-width: 769px) {
  .toggleNavbar, .closeNavbar {
    display: none;
  }
  .header {
    padding: 1.5rem 2rem;
  }
  .header .navBar .navLists {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .header .navBar .navLists .navItem .navLink {
    color: var(--whiteColor);
    padding: 0 0.45rem;
    font-size: 15px !important;
    font-weight: 500;
    transition: color 0.3s ease;
  }

  .header.scrolled .navBar .navLists .navItem .navLink {
    color: var(--textColor);
  }
  .header .navBar .navLists .navItem .navLink:hover {
    color: var(--Madeko);
  }
  .header .navBar .navLists .btn {
    margin-left: 1rem;
  }
  .header .navBar .navLists .btn {
    color: var(--whiteColor);
    font-size: 15px !important;
    font-weight: 500;
  }
}

@media screen and (min-width: 960px) {
  .header .navBar .navLists .navItem .navLink {
    padding: 0 0.7rem;
  }
}

@media screen and (min-width: 1240px) {
  .header {
    padding: 1rem 6.5rem;
  }
}