:root {
  --PrimaryColor: hsl(199, 100%, 33%);
  --SecondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDream: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 240, 246);
  --cardBG: rgb(255, 255, 255);
}

.home {
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: auto;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.carousel img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation: fadeIn 2s ease-in-out;
}

.carousel-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: white;
  width: 12px;
  height: 12px;
}

.home .overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  z-index: 1;
  mix-blend-mode: hard-light;
}

.home video {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  will-change: opacity;
  transition: opacity 0.5s ease-in-out;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
  -o-object-fit: cover;
     object-fit: cover;
}

.home .homeContent {
  width: 100%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  padding: 8rem 1.5rem 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  row-gap: 3rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: auto;
  color: var(--whiteColor);
  z-index: 100;
}

.home .homeContent .textDiv {
  padding: 2rem 0;
}

.home .homeContent .textDiv .smallText {
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 400;
}

.home .homeContent .textDiv .homeTitle {
  position: relative;
  background: var(--MadekoGradient);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-size: 2.8rem;
}

@media screen and (max-width: 500px) {
  .home {
    height: 75vh;
  }

  .home .homeContent .textDiv .smallText {
    font-size: 16px;
  }
  .home .homeContent .textDiv .homeTitle {
    position: relative;
    font-size: 2.3rem;
  }
}

@media screen and (min-width: 940px) {
  .home {
    height: 75vh;
  }
}

@media screen and (min-width: 1240px) {
  .home {
    height: 100vh;
  }

  .home .homeContent {
    padding-left: 0;
    padding-right: 0;
  }
}
