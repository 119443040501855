@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

:root{
  --PrimaryColor: hsl(199, 100%, 33%);
  --SecondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --ThirdColor: #03E829;
  --Madeko: #10BEBB;
  --MadekoGradient: linear-gradient(to left, var(--ThirdColor), var(--Madeko));
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDream: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 240, 246);
  --cardBG: rgb(255, 255, 255);
}

a{
  text-decoration: none;
}

li{
  list-style: none;
  cursor: pointer;
}

.section{
  padding: 4rem 0 2rem;
}

.container{
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.icon{
  font-size: 2rem;
  cursor: pointer;
}

.flex{
  display: flex;
  align-items: center;
}

.grid{
  display: grid;
  align-items: center;
}

.btn{
  padding: .6rem 1.5rem;
  background: var(--MadekoGradient);
  border-radius: 3rem;
  border: none;
  outline: none;
  cursor: pointer;
}

.btn a{
  color: var(--whiteColor);
  font-weight: 500;
}

.btn:hover{
  background: var(--Madeko);
}

img, video{
  width: 100%;
  height: auto;
}

input{
  font-size: 100%;
}

body{
  background: var(--bodyColor);
}

@media screen and (min-width: 556px) {
  .mainContent{
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 800px) {
  .mainContent{
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem !important;
  }
}

@media screen and (min-width: 1240px){
  .container{
    width: 90% !important;
    margin: auto !important;
  }

  .section{
    padding: 6rem 0 4rem;
  }
}
