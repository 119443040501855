:root{
    --PrimaryColor: hsl(199, 100%, 33%);
    --SecondaryColor: hsl(187, 85%, 43%);
    --ThirdColor: hsl(147, 92%, 43%);
    --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(201, 33%, 16%);
    --textColor: hsl(240, 4%, 36%);
    --whiteColorDream: hsl(0, 0%, 93%);
    --greyText: rgb(190, 190, 190);
    --inputColor: rgb(239, 239, 239);
    --bodyColor: rgb(240, 240, 246);
    --cardBG: rgb(255, 255, 255);
}

.info{
    .secContainer{
        .titlesDiv{
            text-align: center;
            margin-bottom: 3rem;

            h2{
                padding: 1rem 0;
                font-size: 25px;
                color: var(--blackColor);
            }

            p{
                text-align: left;
                font-size: 15px;
                color: var(--textColor);
                line-height: 22px;
            }
        }

        .mainContent{
            padding: 2rem 0;
            gap: 1rem;

            .filInfo{
                overflow: hidden;
                border-radius: 1rem;
                box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.334);

                .infoImage{
                    height: 200px;
                    width: 100%;
                    position: relative;
                    overflow: hidden;
                    video{
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }

                    .overlayInfo{
                        display: flex;
                        position: absolute;
                        align-items: flex-start;
                        padding: 0;
                        justify-content: center;
                        flex-direction: column;
                        background: linear-gradient(rgba(0, 0, 0, 0.846), rgba(0, 0, 0, 0.759));
                        bottom: 0;
                        width: 100%;
                        height: 0;
                        overflow: hidden;

                        h2{
                            color: var(--whiteColor);
                            font-weight: 600;
                        }

                        p{
                            color: var(--whiteColor);
                            opacity: .7;
                            font-size: 15px;
                        }

                        .icon{
                            position: absolute;
                            right: 10px;
                            bottom: 10px;
                            padding: .5rem;
                            background: var(--PrimaryColor);
                            font-size: 2.5rem;
                            border-radius: 50%;
                            border: 1px solid transparent;
                            color: var(--whiteColor);
                            transition: .3s ease;

                            &:hover{
                                background: var(--SecondaryColor);
                                border: 1px solid transparent;
                            }
                        }
                    }
                }

                .infoFooter{
                    padding: 1rem;

                    .number{
                        font-size: 2rem;
                        font-weight: 700;
                        color: var(--blackColor);
                        position: relative;

                        span{
                            font-size: 26px;
                        }
                    }

                    .infoText{
                        justify-content: space-between;

                        p{
                            color: var(--blackColor);
                            font-size: 15px;
                        }
                    }
                }

                &:hover{
                    .overlayInfo{
                        overflow: visible;
                        padding: 1rem 1.5rem;
                        height: 100%;

                    }
                }
            }
        }
        
    }
}

// Media queries ======================================================>
@media screen and (min-width: 480px) {
    .info{
        padding-top: 5rem;
    }
}