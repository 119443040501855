:root {
  --PrimaryColor: hsl(199, 100%, 33%);
  --SecondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDream: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 240, 246);
  --cardBG: rgb(255, 255, 255);
}

.footer {
  width: 100%;
  position: relative;
  padding: 2rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: auto;
}

.footer .videoDiv {
  position: absolute;
  height: 100%;
  width: 100%;
}

.footer .videoDiv video {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.footer .videoDiv::after {
  content: '';
  position: absolute;
  background: rgba(10, 84, 107, 0.307);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  mix-blend-mode: multiply;
}

.footer .secContent {
    width: 100%;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    padding: 2rem initial;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: auto;
    row-gap: 2rem;
    z-index: 100;
}

.footer .secContent .contactDiv {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1rem;
}

.footer .secContent .contactDiv .text small {
  font-size: 15px;
  font-weight: 400;
  text-align: justify;
  color: var(--whiteColor);
  margin-bottom: 1.5rem;
}

.footer .secContent .contactDiv .text h2 {
  color: var(--whiteColor);
  text-align: justify;
}

.footer .secContent .contactDiv .inputDiv {
  width: 100%;
  gap: 1rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.footer .secContent .contactDiv .inputDiv .text {
  width: 100%;
  padding: .5rem .6rem;
  border-radius: 3rem;
  margin-bottom: 1rem;
  border: 1px solid var(--whiteColor);
  outline: none;
  background: transparent;
  color: var(--whiteColor);
}

.footer .secContent .contactDiv .inputDiv .textarea {
    width: 100%;
    height: 75px;
    padding: .5rem .6rem;
    border-radius: 15px;
    margin-bottom: 1rem;
    border: 1px solid var(--whiteColor);
    outline: none;
    background: transparent;
    color: var(--whiteColor);
}

.footer .secContent .contactDiv .inputDiv input::-webkit-input-placeholder {
  color: var(--whiteColor);
  opacity: .5;
}

.footer .secContent .contactDiv .inputDiv input:-ms-input-placeholder {
  color: var(--whiteColor);
  opacity: .5;
}

.footer .secContent .contactDiv .inputDiv input::-ms-input-placeholder {
  color: var(--whiteColor);
  opacity: .5;
}

.footer .secContent .contactDiv .inputDiv .sendMessage {
    justify-self: right;
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: var(--whiteColor);
    font-size: 15px;
    font-weight: 500;
    gap: .5rem;
}

.footer .secContent .contactDiv .inputDiv .btn {
  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: var(--whiteColor);
  font-size: 15px;
  font-weight: 500;
  gap: .5rem;
}

.email {
    color: orangered;
}

.footer .secContent .contactDiv .inputDiv .btn .icon {
  font-size: 18px;
}

.footer .secContent .footerCard {
  position: relative;
  padding: 1rem 1rem 4rem;
  gap: 2rem;
  border-radius: 1rem;
  background: var(--bodyColor);
  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  overflow: hidden;
}

.footer .secContent .footerCard .footerIntro {
  -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: .5rem;
}

.footer .secContent .footerCard .logoDiv .logo {
  color: var(--blackColor);
  font-weight: 600;
  cursor: pointer;
    object-fit: contain;
}

.footer .secContent .footerCard .logoDiv .logo .textNextLogo {
    flex-direction: column;
}

.footer .secContent .footerCard .logoDiv .logo img {
  width: 75px;
  height: 65px;
    margin-right: .5rem;
}

.footer .secContent .footerCard .footerParagraph {
    font-size: 15px !important;
    line-height: 22px;
    color: var(--textColor);
}

.footer .secContent .footerCard .footerSocials {
  -webkit-column-gap: .5rem;
          column-gap: .5rem;
}

.footer .secContent .footerCard .footerContactSection .footerContact {
    gap: .5rem;
}

.footer .secContent .footerCard .footerContactSection .footerContact .contactImage {
    margin-bottom: .5rem;
    width: 24px;
    height: 24px;
}

.footer .secContent .footerCard .footerSocials .icon {
    color: var(--blackColor);
    font-size: 24px;
    border-radius: 50%;
}

.footer .secContent .footerCard .footerSocials .icon:hover {
  color: var(--Madeko);
}

.footer .secContent .footerCard .footerLinks {
  -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
  width: 100%;
  gap: 1rem;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.footer .secContent .footerCard .footerLinks .linkGroup .groupTitle {
  display: block;
  font-weight: 600;
  color: var(--blackColor);
  margin-bottom: .5rem;
}

.footer .secContent .footerCard .footerLinks .linkGroup .footerList {
  font-size: 15px;
  color: var(--textColor);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  padding: 5px 0;
}

.footer .secContent .footerCard .footerLinks .linkGroup .footerList .icon {
  font-size: 15px;
  color: var(--greyText);
}

.footer .secContent .footerCard .footerLinks .linkGroup .footerList:hover {
  color: var(--Madeko);
  -webkit-transform: translateX(7px);
          transform: translateX(7px);
}

.footer .secContent .footerCard .footerLinks .linkGroup .footerList:hover .icon {
  color: var(--Madeko);
}

.footer .secContent .footerCard .footerDiv {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background: var(--Madeko);
  padding: .5rem .2rem;
}

.footer .secContent .footerCard .footerDiv small {
  font-size: 14px;
  color: var(--whiteColor);
}

@media screen and (min-width: 500px) {
  .footer .secContent .footerCard .footerLinks {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
    padding-bottom: 1rem;
  }
  .footer .secContent .footerCard .footerDiv {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .footer .secContent .footerCard .footerDiv small {
    font-size: 14px;
    color: var(--whiteColor);
  }
}

@media screen and (min-width: 660px) {
  .footer .secContent .contactDiv {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .footer .secContent .contactDiv .inputDiv {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .footer .secContent .contactDiv .inputDiv input {
    width: 60%;
  }
  .footer .secContent .contactDiv .inputDiv .btn {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: 100%;
  }
  .footer .secContent .footerDiv {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .footer .secContent .footerDiv small {
    font-size: 14px;
    color: var(--whiteColor);
  }
  .footer .secContent .footerCard .linkGroup .footerList {
    padding: 5px 0;
  }
}

@media screen and (min-width: 840px) {
  .footer {
    padding: 2rem 0;
  }
  .footer .secContent .footerCard {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .footer .secContent .footerCard .footerParagraph {
    font-size: 15px;
    color: var(--textColor);
    padding: 5px 0;
  }
  .footer .secContent .footerCard .footerLinks {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
    padding-top: 2.5rem;
  }
  .footer .secContent .footerCard .footerLinks .linkGroup .footerList {
    padding: 5px 0;
  }
}

@media screen and (min-width: 1024px) {
  .footer .secContent .footerCard {
    padding: 3rem 1rem;
  }
  .footer .secContent .footerCard .footerIntro .footerParagraph {
    font-size: 15px;
    color: var(--textColor);
    padding: 5px 0;
  }
  .footer .secContent .footerCard .footerIntro .footerSocials {
    -webkit-column-gap: .5rem;
            column-gap: .5rem;
  }
  .footer .secContent .footerCard .footerIntro .footerSocials .icon {
    color: var(--textColor);
    font-size: 25px;
  }
  .footer .secContent .footerCard .footerIntro .footerSocials .icon:hover {
    color: var(--Madeko);
  }
  .footer .secContent .footerCard .footerLinks .groupTitle {
    font-weight: 600;
  }
  .footer .secContent .footerCard .footerLinks .linkGroup .footerList {
    font-size: 16px;
    padding: 5px 0;
  }
}

.spinner-container {
    display: inline-block;
    margin-left: 10px;
}

.spinner {
    width: 20px;
    height: 20px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top: 3px solid #000;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.contactLink {
    text-decoration: none;
    color: inherit;
}

.contactLink:hover {
    color: var(--Madeko);
}
