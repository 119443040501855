:root{
    --PrimaryColor: hsl(199, 100%, 33%);
    --SecondaryColor: hsl(187, 85%, 43%);
    --ThirdColor: hsl(147, 92%, 43%);
    --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(201, 33%, 16%);
    --textColor: hsl(240, 4%, 36%);
    --whiteColorDream: hsl(0, 0%, 93%);
    --greyText: rgb(190, 190, 190);
    --inputColor: rgb(239, 239, 239);
    --bodyColor: rgb(240, 240, 246);
    --cardBG: rgb(255, 255, 255);
}

.p{
    font-size: 15px;
    color: var(--textColor);
    line-height: 22px;
}

.h4{
    padding: 1rem 0;
    font-weight: 600;
    color: var(--blackColor);
}

.h2{
    padding: 1rem 0;
    font-size: 25px;
    color: var(--blackColor);
}

.materials{
    .sectionContainer{
        text-align: center;

        .materialsContainer{
            margin-top: 2rem;
            gap: 1.5rem;

            .singleMaterial{
                position: relative;
                height: 320px;
                width: 85%;
                max-width: 250px;
                overflow: hidden;
                border-radius: 10rem;
                gap: 1rem;
                margin: auto;

                .materialImage, .ownerImage, .ownerName{
                    position: absolute;
                }

                .materialImage{
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom:0;
                    object-fit: cover;
                }

                .ownerImage{
                    height: 50px;
                    width: 50px;
                    object-fit: cover;   
                    border-radius: 50%;
                    bottom: 90px;
                    left: 40%;
                    transform: translateX(-50%);  
                    border: 5ps solid rgba(225, 225, 225, 0.637);
                    transition: .4s ease-in-out; 
                    animation: animated 2.3s infinite;     
                }

                @keyframes animated{
                    0%{
                        transform: translateY(0px);
                    }
                    50%{
                        transform: translateY(8px);
                    }
                    100%{
                        transform: translateY(0px);
                    }
                }

                .ownerName{
                    bottom: 0;
                    width: 100%;
                    height: max-content;
                    background: var(--whiteColor);
                    padding: 1.5rem 1rem;
                    transition: .4s ease-in-out;
                    animation: animatedBorders 2.3s infinite; 

                    span{
                        display: block;
                        color: var(--blackColor);
                        font-weight: 700;
                    }
                }

                 @keyframes animatedBorders{
                    0%{
                        transform: translateY(0px);
                    }
                    50%{
                        transform: translateY(8px);
                    }
                    100%{
                        transform: translateY(0px);
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 551px) {
    .materials{
        .materialsContainer{
                grid-template-columns: repeat(2,1fr);
                gap: 2rem;
                margin-top: 5rem;

        }
    }
}
